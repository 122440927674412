<template>
  <div class="h-screen flex w-full bg-full-color">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/verify.png"
                alt="login"
                class="mx-auto main-img"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg text-wrapper auth-tabs-container"
            >
              <div v-if="loading" class="vx-card__title mt-8 mb-8">
                <h4 class="mb-4">Account verification in progress.</h4>
                <img src="@/assets/images/pages/loader.gif" />
              </div>

              <div
                v-if="is_verified && !loading"
                class="vx-card__title mt-6 mb-6"
              >
                <h4 class="mb-4">Account verified</h4>
                <p>{{ success_msg }} Now you can login to the panel.</p>
                <div class="mt-6">
                  <vs-button
                    type="border"
                    to="/login"
                    class="w-full sm:w-auto sm:mb-auto sm:mt-auto"
                    >Go to login</vs-button
                  >
                </div>
              </div>

              <div
                v-if="!is_verified && !loading"
                class="vx-card__title mt-8 mb-8"
              >
                <h4 class="mb-4">Verification problem.</h4>
                <p>{{ error_msg }}</p>
                <div class="mt-6">
                  <vs-button
                    type="border"
                    to="/verify/resend"
                    class="w-full sm:w-auto sm:mb-auto sm:mt-auto"
                    >Resend verification code</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { AuthAction } from '@/store/actionTypes';

import { getMessageFromError } from '@/helpers';

export default {
  data: () => ({
    loading: true,
    is_verified: false,
    success_msg: '',
    error_msg: '',
  }),
  computed: {
    verificationCode() {
      return this.$route.query.verification_code;
    },
  },
  methods: {
    ...mapActions('auth', [AuthAction.verifyEmail]),

    removeVerificationParam() {
      let query = Object.assign({}, this.$route.query);
      delete query.verification_code;
      this.$router.replace({ query });
    },
  },
  async mounted() {
    const payload = { verification_code: this.verificationCode };

    try {
      const { message } = await this.verifyEmail(payload);

      this.success_msg = message;
      this.is_verified = true;
      this.loading = false;
      this.removeVerificationParam();
    } catch (error) {
      this.error_msg = getMessageFromError(error);
      this.is_verified = false;
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.main-img {
  width: 60%;
}

.text-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>
